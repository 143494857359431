import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { FC } from 'react';
import getConfig from 'next/config';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import plPL from 'antd/lib/locale/pl_PL';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { DefaultSeo } from 'next-seo';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { ToastContainer } from 'react-toastify';
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { wrapper } from 'reducer/store';
import * as gtag from 'lib/gtag';
import theme from 'styles/theme';
import antdTheme from 'styles/antd-theme';
import GlobalStyle from 'styles/GlobalStyle';
import 'nprogress/nprogress.css';
import { login } from 'reducer/actions';
import { State } from 'reducer/reducer';
import { getUser, isTokenValid } from 'lib/authentication';
import 'react-toastify/dist/ReactToastify.css';
import { GTM_ID } from 'lib/gtag';

const LoginModal = dynamic(() => import('containers/LoginModal'), {
    ssr: false
});

export const App: FC<AppProps> = ({ Component, pageProps }) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const loginModalVisible = useSelector((state: State) => state.loginModalVisible);
    useEffect(() => {
        const handleRouteChangeComplete = (url: string) => {
            gtag.pageview(url);
            NProgress.done();
        };

        const handleRouteChangeStart = () => {
            NProgress.start();
        };

        const handleRouteChangeError = () => {
            NProgress.done();
        };

        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        router.events.on('hashChangeComplete', handleRouteChangeComplete);
        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeError', handleRouteChangeError);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
            router.events.off('hashChangeComplete', handleRouteChangeComplete);
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeError', handleRouteChangeError);
        };
    }, [router.events]);

    useEffect(() => {
        if (isTokenValid()) {
            dispatch(login(getUser()));
        }
    }, []);

    const { name, url } = getConfig().publicRuntimeConfig;
    const openGraph = {
        type: 'website',
        locale: 'pl_PL',
        url,
        site_name: name,
        description: `elektrotechnik AUTOMATYK” jest czasopismem branżowym poruszającym tematykę związaną z elektrotechniką i automatyką przemysłową. Na łamach magazynu prezentowane są nowe produkty, aplikacje i nowoczesne technologie mające wpływ na zwiększenie potencjału produkcyjnego firm przemysłowych. „Elektrotechnik AUTOMATYK” adresowany jest do inżynierów produkcji, a także. technicznej kadry zarządzającej w przemyśle wytwórczym. Czasopismo jest polską edycją tytułu „Elektrotechnik AUTOMATISIERUNG” wydawanego przez międzynarodowy holding wydawniczy Vogel Business Media, jednego ze światowych liderów na rynku mediów branżowych. Wydawcą polskiej edycji tytułu jest wydawnictwo Raven Media.`,
        images: [
            {
                url: `${url}/static/images/logo.svg`,
                width: 325,
                height: 46,
                alt: 'Logotype'
            }
        ]
    };

    const twitter = {
        cardType: 'summary_large_image'
    };

    return (
        <ThemeProvider theme={theme}>
            <ConfigProvider locale={plPL} theme={antdTheme}>
                <ToastContainer
                    position="top-right"
                    autoClose={8000}
                    hideProgressBar
                    newestOnTop
                    draggable={false}
                    closeOnClick
                    pauseOnHover
                />
                <DefaultSeo
                    titleTemplate={`%s | ${name}`}
                    defaultTitle={`elektrotechnik AUTOMATYK - czasopismo i portal, elektrotechnika, automatyka, robotyka`}
                    description="elektrotechnik AUTOMATYK” jest czasopismem branżowym poruszającym tematykę związaną z elektrotechniką i automatyką przemysłową. Na łamach magazynu prezentowane są nowe produkty, aplikacje i nowoczesne technologie mające wpływ na zwiększenie potencjału produkcyjnego firm przemysłowych. „Elektrotechnik AUTOMATYK” adresowany jest do inżynierów produkcji, a także. technicznej kadry zarządzającej w przemyśle wytwórczym. Czasopismo jest polską edycją tytułu „Elektrotechnik AUTOMATISIERUNG” wydawanego przez międzynarodowy holding wydawniczy Vogel Business Media, jednego ze światowych liderów na rynku mediów branżowych. Wydawcą polskiej edycji tytułu jest wydawnictwo Raven Media."
                    openGraph={openGraph}
                    twitter={twitter}
                />
                {loginModalVisible && <LoginModal />}
                <GoogleTagManager gtmId={GTM_ID} />
                <Component {...pageProps} />
            </ConfigProvider>
            <GlobalStyle />
        </ThemeProvider>
    );
};

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
    const { analyze } = getConfig().publicRuntimeConfig;
    if (analyze) console.log({ id, name, label, value });
    sendGTMEvent({
        event_label: id,
        name,
        event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(name === 'CLS' ? value * 1000 : value)
    });
}

export default wrapper.withRedux(App);
